<template>
    <div class="BasicData">
        <div class="title">
            <span>{{ title }}</span>
        </div>
        <div class="content">
            <slot>
                <div class="BasicDataBoard">
                    <div class="BasicDataBoard-item">
                        <div class="BasicData_icon BasicData_icon--building"></div>
                        <p class="BasicData_subtext">建筑面积</p>
                        <p class="BasicData_text">20,000 m²</p>
                    </div>
                    <div class="BasicDataBoard-item">
                        <div class="BasicData_icon BasicData_icon--home"></div>
                        <p class="BasicData_subtext">入住部门</p>
                        <p class="BasicData_text">160</p>
                    </div>

                    <div class="BasicDataBoard-item">
                        <div class="BasicData_icon BasicData_icon--person"></div>
                        <p class="BasicData_subtext">入住人员</p>
                        <p class="BasicData_text">5,860</p>
                    </div>

                    <div class="BasicDataBoard-item">
                        <div class="BasicData_icon BasicData_icon--parking"></div>
                        <p class="BasicData_subtext">停车位</p>
                        <p class="BasicData_text">2,600</p>
                    </div>

                    <div class="BasicDataBoard-item">
                        <div class="BasicData_icon BasicData_icon--car"></div>
                        <p class="BasicData_subtext">注册车辆</p>
                        <p class="BasicData_text">1,800</p>
                    </div>

                    <div class="BasicDataBoard-item">
                        <div class="BasicData_icon BasicData_icon--iot"></div>
                        <p class="BasicData_subtext">物联设备</p>
                        <p class="BasicData_text">2,800</p>
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasicData',
    props: {
        title: {
            type: String,
            default: '基础信息'
        }
    }
};
</script>

<style lang="less">
.BasicData {
    display: block;
    overflow: hidden;
    width: 580px;
    height: 33%;
    margin: 0 10px 20px 10px;
    background-image: url('./../../assets/image/card_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .title {
        height: 30px;
        padding-left: 34px;
        padding-top: 20px;

        > span {
            font-size: 24px;
            font-weight: normal;
            font-style: italic;
            color: #a1e4ff;
            padding-bottom: 20px;

            background: linear-gradient(-35deg, #a0cbd9 0%, #ddfdff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .content {
        display: block;
        height: 80%;
        position: relative;
    }
}
</style>

<style>
body {
    background-color: #1e1f26;
    color: white;
    font-family: Arial, sans-serif;
}

.BasicDataBoard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 20px;
}

.BasicDataBoard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
}

.BasicDataBoard-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.BasicData_icon {
    width: 48px; /* 设置图标宽度 */
    height: 48px; /* 设置图标高度 */
    margin-bottom: 8px;
    background-size: contain;
    background-repeat: no-repeat;
}

.BasicData_text {
    font-size: 1.5rem;
    font-weight: bold;
}

.BasicData_subtext {
    color: #999;
}

.BasicData_icon--building { background-image: url('./../../assets/image/building.png'); }
.BasicData_icon--home { background-image: url('./../../assets/image/home.png'); }
.BasicData_icon--person { background-image: url('./../../assets/image/person.png'); }
.BasicData_icon--parking { background-image: url('./../../assets/image/parking.png'); }
.BasicData_icon--car { background-image: url('./../../assets/image/car.png'); }
.BasicData_icon--iot { background-image: url('./../../assets/image/iot.png'); }
</style>