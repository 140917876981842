import request from '@/utils/request'

// 根据产品ID获取缓存的物模型
export function cacheJsonThingsModel (productId) {
  return request({
    url: '/iot/model/cache/' + productId,
    method: 'get'
  })
}

// 查询设备运行状态详细
export function getDeviceRunningStatus (params) {
  return request({
    url: '/iot/device/runningStatus',
    method: 'get',
    params: params
  })
}

// 查询设备详细
export function getDevice (deviceId) {
  return request({
    url: '/iot/device/' + deviceId,
    method: 'get'
  })
}

// 查询设备监测数据
export function listHistory (query) {
  return request({
    url: '/iot/deviceLog/history',
    method: 'get',
    params: query
  })
}

// 查询设备总览
export function getDeviceMain (query) {
  return request({
    url: '/juncong/api/deviceScreen',
    method: 'get',
    params: query
  })
}

// 查询设备运行分析
export function getDeviceRun (query) {
  return request({
    url: '/juncong/api/deviceRunStatus',
    method: 'get',
    params: query
  })
}

// 查询水电气分析
export function waterGasAnalyze (query) {
  return request({
    url: '/juncong/api/waterElecAri',
    method: 'get',
    params: query
  })
}

// 查询环境分析
export function environmentAnalyze (query) {
  return request({
    url: '/juncong/api/envirRunStatus',
    method: 'get',
    params: query
  })
}

// 告警数量
export function alarmsNumber (query) {
  return request({
    url: '/juncong/api/preMonthWarn',
    method: 'get',
    params: query
  })
}

// 告警数量趋势
export function alarmsNumberAnalyze (query) {
  return request({
    url: '/juncong/api/warnNumber',
    method: 'get',
    params: query
  })
}

// 设备信号分析
export function getdeviceSignal (query) {
  return request({
    url: '/juncong/api/deviceSign',
    method: 'get',
    params: query
  })
}
