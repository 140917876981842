<template>
    <div class="Environment">
        <div class="title">
            <span>{{ title }}</span>
        </div>
        <div class="content">
            <slot></slot>
            <div class="EnvironmentBoard">
                <div class="EnvironmentBoard-item" v-for="(item, index) in items" :key="index">
                    <div class="icon-container">
                        <div :class="['Environment_icon', item.iconClass]"></div>
                        <span class="Environment_text">{{ item.text }}</span>
                    </div>
                    <span class="Environment_subtext">{{ item.subtext }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Environment',
    props: {
        title: {
            type: String,
            default: '环境监测'
        }
    },
    data() {
        return {
            items: [
                {
                    iconClass: 'Environment_icon--building',
                    text: '杭州市',
                    subtext: '多云转晴'
                },
                {
                    iconClass: 'Environment_icon--shidu',
                    text: '湿度',
                    subtext: '46%'
                },
                {
                    iconClass: 'Environment_icon--PM25',
                    text: 'PM2.5',
                    subtext: '36 μg/m³'
                }
            ]
        };
    }
};
</script>

<style lang="less">
.Environment {
  display: block;
  overflow: hidden;
  width: 580px;
  height: 20%;
  margin: 0 10px 20px 10px;
  background-image: url('./../../assets/image/card_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .title {
    height: 30px;
    padding-left: 34px;
    padding-top: 20px;

    > span {
      font-size: 24px;
      font-weight: normal;
      font-style: italic;
      color: #a1e4ff;
      padding-bottom: 20px;

      background: linear-gradient(-35deg, #a0cbd9 0%, #ddfdff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .content {
    display: block;
    height: 80%;
    position: relative;
    position: relative;
  }
}
</style>

<style lang="less">
body {
    background-color: #1e1f26;
    color: white;
    font-family: Arial, sans-serif;
}

.EnvironmentBoard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 20px;
}

.EnvironmentBoard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    //background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
}

.icon-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.Environment_icon {
    width: 60px; /* 设置图标宽度 */
    height: 60px; /* 设置图标高度 */
    margin-right: 10px; /* 图标与文本之间的间距 */
    background-size: contain;
    background-repeat: no-repeat;
}

.Environment_text {
    font-size: 2rem;
    //font-weight: bold;
}

.Environment_subtext {
    font-size: 1.5rem;
    color: #ffffff;
}

.Environment_icon--building { background-image: url('./../../assets/image/building.png'); }
.Environment_icon--shidu { background-image: url('./../../assets/image/湿度.svg'); }
.Environment_icon--PM25 { background-image: url('./../../assets/image/PM2.5.svg'); }
</style>
