<template>
    <div class="VehicleAccessLog">
        <div class="VehicleAccessLog-title">
            <span>{{ title }}</span>
            <!-- 筛选下拉菜单 -->
            <div class="filter">
                <label for="statusFilter">筛选状态:</label>
                <select id="statusFilter" v-model="selectedStatus" @change="filterEvents">
                    <option value="">全部</option>
                    <option value="进">进</option>
                    <option value="出">出</option>
                </select>
            </div>
        </div>
        <div class="content">
            <slot></slot>
            <div>
                <!-- 事件表格 -->
                <table class="VehicleAccessLog-table">
                    <thead>
                    <tr>
                        <th>车辆牌号</th>
                        <th>出入口</th>
                        <th>时间</th>
                        <th>状态</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(event, index) in filteredEvents" :key="index">
                        <td>{{ event.licensePlate }}</td>
                        <td>{{ event.entryExitPoint }}</td>
                        <td>{{ event.time }}</td>
                        <td :style="{ color: event.status === '进' ? '#FFA500' : '' }">{{ event.status }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VehicleAccessLog',
    props: {
        title: {
            type: String,
            default: '车辆出入记录'
        }
    },
    data() {
        return {
            selectedStatus: '', // 当前选择的状态
            events: [
                { licensePlate: '浙A4**23', entryExitPoint: 'G1-DZ-201', time: '2024-10-10 14:28', status: '进' },
                { licensePlate: '浙A5**26', entryExitPoint: 'G1-DZ-203', time: '2024-10-10 13:27', status: '出' },
                { licensePlate: '浙A6**08', entryExitPoint: 'G1-DZ-202', time: '2024-10-10 12:51', status: '进' },
                { licensePlate: '浙A4**20', entryExitPoint: 'G1-DZ-204', time: '2024-10-10 12:06', status: '进' },
                { licensePlate: '浙AM**68', entryExitPoint: 'G1-DZ-208', time: '2024-10-10 10:42', status: '进' },
                { licensePlate: '浙AM**K5', entryExitPoint: 'G1-DZ-201', time: '2024-10-10 09:34', status: '出' },
                { licensePlate: '浙A6**08', entryExitPoint: 'G1-DZ-205', time: '2024-10-10 08:05', status: '出' },
                // 添加更多事件
            ]
        };
    },
    computed: {
        filteredEvents() {
            if (this.selectedStatus === '') {
                return this.events;
            }
            return this.events.filter(event => event.status === this.selectedStatus);
        }
    },
    methods: {
        filterEvents() {
            // 这里可以添加其他逻辑，如果需要的话
        }
    }
};
</script>

<style lang="less">
.VehicleAccessLog {
    display: block;
    overflow: hidden;
    width: 580px;
    height: 66%;
    margin: 0 10px 20px 10px;
    background-image: url('./../../assets/image/card_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .VehicleAccessLog-title {
        display: flex; /* 使用 Flexbox 布局 */
        align-items: center; /* 垂直居中对齐 */
        padding-left: 34px;
        padding-top: 20px;

        > span {
            font-size: 24px;
            font-weight: normal;
            font-style: italic;
            color: #a1e4ff;
            padding-bottom: 20px;

            background: linear-gradient(-35deg, #a0cbd9 0%, #ddfdff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .filter {
            margin-right: auto;
            margin-left: auto; /* 将筛选下拉菜单推到右边 */
            display: flex;
            align-items: center; /* 垂直居中对齐 */

            label {
                font-size: 24px; /* 设置筛选状态标签的字体大小 */
                margin-right: 10px;
            }

            select {
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
            }
        }
    }

    .content {
        display: block;
        height: 80%;
        position: relative;
    }
}
</style>

<style scoped>
.VehicleAccessLog-table {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-collapse: collapse;
    margin-top: 10px;
}

.VehicleAccessLog-table th,
.VehicleAccessLog-table td {
    padding: 10px;
    text-align: left;
    vertical-align: top;
    border: 1px solid #333;
}

.VehicleAccessLog-table tr:hover {
    background-color: #222;
}
</style>