<template>
    <div class="DeviceData">
        <div class="title">
            <span>{{ title }}</span>
        </div>
        <div class="content">
            <slot></slot>
            <div>
                <table class="DeviceData_table">
                    <thead class="DeviceData_thead">
                    <tr>
                        <th scope="col"></th>
<!--                        <div class="DeviceData_icon DeviceData_icon&#45;&#45;XF"></div>-->
                        <th scope="col">消防</th>
                        <th scope="col">供配电</th>
<!--                        <th scope="col" class="DeviceData_icon DeviceData_icon&#45;&#45;PD">供配电</th>-->
                        <th scope="col">给排水</th>
<!--                        <th scope="col" class="DeviceData_icon DeviceData_icon&#45;&#45;PS">给排水</th>-->
                        <th scope="col">暖通空调</th>
<!--                        <th scope="col" class="DeviceData_icon DeviceData_icon&#45;&#45;KT">暖通空调</th>-->
                    </tr>
                    </thead>
                    <tbody class="DeviceData_tbody">
                    <tr>
                        <th scope="row">开:</th>
                        <td>{{ data.消防 }}</td>
                        <td>{{ data.供配电 }}</td>
                        <td>{{ data.给排水 }}</td>
                        <td>{{ data.暖通空调 }}</td>
                    </tr>
                    <tr>
                        <th scope="row">总:</th>
                        <td>{{ data.消防 }}h</td>
                        <td>{{ data.供配电 }}h</td>
                        <td>{{ data.给排水 }}h</td>
                        <td>{{ data.暖通空调 }}h</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceData',
    props: {
        title: {
            type: String,
            default: '车辆数据'
        }
    },
    data() {
        return {
            data: {
                关: '2',
                开: '180',
                消防: '0',
                供配电: '358',
                给排水: '266',
                暖通空调: '120'
            }
        }
    }
};
</script>

<style lang="less">
.DeviceData {
    display: block;
    overflow: hidden;
    width: 580px;
    height: 20%;
    margin: 0 10px 20px 10px;
    background-image: url('./../../assets/image/card_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .title {
        height: 30px;
        padding-left: 34px;
        padding-top: 20px;

        > span {
            font-size: 24px;
            font-weight: normal;
            font-style: italic;
            color: #a1e4ff;
            padding-bottom: 20px;

            background: linear-gradient(-35deg, #a0cbd9 0%, #ddfdff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .content {
        display: block;
        height: 80%;
        position: relative;
        position: relative;
    }
}

.DeviceData_table {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.DeviceData_thead th {
    text-align: center;
    font-size: 22px;
    padding: 14px;
}

.DeviceData_tbody td {
    text-align: center;
    font-size: 24px;
    padding: 16px;
}

.DeviceData_icon {
    width: 3px; /* 设置图标宽度 */
    height: 3px; /* 设置图标高度 */
    background-size: contain;
    background-repeat: no-repeat;

    display: inline-block;
    //width: 12px; /* 设置图标宽度 */
    //height: 12px; /* 设置图标高度 */
    //background-size: contain;
    //background-repeat: no-repeat;
    //position: absolute;
    //left: 14px; /* 调整位置以匹配padding */
    //top: 50%;
    //transform: translateY(-50%); /* 垂直居中 */
}

.DeviceData_icon--XF { background-image: url('./../../assets/image/消防.svg'); }
.DeviceData_icon--PD { background-image: url('./../../assets/image/供配电.svg'); }
.DeviceData_icon--PS { background-image: url('./../../assets/image/给排水.svg'); }
.DeviceData_icon--KT { background-image: url('./../../assets/image/暖通空调.svg'); }
</style>
