<template>
    <div class="ParkingLog">
        <div class="title">
            <span>{{ title }}</span>
        </div>
        <div class="content">
            <slot></slot>
            <div class="info">
                <span>当前车辆：</span><span class="span2">{{ currentVehicles }}</span>
                <span>车位利用率：</span><span class="span2">{{ parkingUtilization }}%</span>
            </div>
            <div id="parking-lot-chart" style="width: 100%; height: 300px;"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    name: 'ParkingLot',
    props: {
        title: {
            type: String,
            default: '车辆数据'
        }
    },
    data() {
        return {
            // 假设这是你的数据源
            totalSpots: [400, 300, 300], // 总车位数
            occupiedSpots: [300, 200, 100] // 已占用的车位数
        };
    },
    computed: {
        currentVehicles() {
            // 计算当前车辆总数
            return this.occupiedSpots.reduce((acc, val) => acc + val, 0);
        },
        parkingUtilization() {
            // 计算车位利用率
            const total = this.totalSpots.reduce((acc, val) => acc + val, 0);
            const occupied = this.occupiedSpots.reduce((acc, val) => acc + val, 0);
            return ((occupied / total) * 100).toFixed(2); // 保留两位小数
        }
    },
    mounted() {
        this.initChart();
    },
    methods: {
        initChart() {
            const chart = echarts.init(document.getElementById("parking-lot-chart"));
            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                legend: {
                    data: ["占用", "空闲"]
                },
                xAxis: {
                    splitLine: {
                        lineStyle: {
                            type: "dashed"
                        }
                    }
                },
                yAxis: {
                    data: ["G", "B1", "B2"],
                    axisLabel: {
                        interval: 0,
                        rotate: 45
                    }
                },
                series: [
                    {
                        name: "占用",
                        type: "bar",
                        barWidth: "60%",
                        itemStyle: {
                            color: "#5ef3b7" // 修改为绿色
                        },
                        // data: [300, 200, 100]
                        data: this.occupiedSpots
                    },
                ]
            };

            chart.setOption(option);
        }
    }
};
</script>

<style lang="less">
.ParkingLog {
  display: block;
  overflow: hidden;
  width: 580px;
  height: 30%;
  margin: 0 10px 20px 10px;
  background-image: url('./../../assets/image/card_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .title {
    height: 30px;
    padding-left: 34px;
    padding-top: 20px;

    > span {
      font-size: 24px;
      font-weight: normal;
      font-style: italic;
      color: #a1e4ff;
      padding-bottom: 20px;

      background: linear-gradient(-35deg, #a0cbd9 0%, #ddfdff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .content {
    display: block;
    height: 80%;
    position: relative;
    position: relative;
  }
}

.info {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-bottom: 20px; /* 下边距 */
}

.info span {
    margin-left: 10px;
    margin-right: 0px; /* 左右间距 */
    font-size: 16px;
    color: #ffffff;
}

.info .span2 {
    margin-left: 0px;
    margin-right: 10px; /* 左右间距 */
    font-size: 16px;
    color: #a1e4ff;
}
</style>
