<template>
    <div>
        <Environment class="right animated bounceInUp"/>
        <ParkingLot class="right animated bounceInUp card2-animate"/>
        <DiveceData class="right animated bounceInUp card3-animate"/>
    </div>
</template>

<script>
import option7 from './chart/option7';
import option8 from './chart/option8';
import option3 from './chart/option3';
import option10 from './chart/option10';
import option12 from './chart/option12';
import Environment from "./common/Environment.vue";
import ParkingLot from "./common/ParkingLot.vue";
import DiveceData from "./common/DeviceData.vue";

export default {
    name: 'Left',
    components: {
        DiveceData,
        ParkingLot,
        Environment,
    },
    data: () => ({
        option7,
        option8,
        option3,
        option10,
        option12
    })
};
</script>

<style lang="less" scoped>
.dv {
  width: 520px;
  height: 100%;
  padding-left: 36px;
}

.right {
  float: right;
}

.card1-animate {
  animation-delay: 0s;
}

.card2-animate {
  animation-delay: 0.2s;
}

.card3-animate {
  animation-delay: 0.4s;
}

.card4-animate {
  animation-delay: 0.6s;
}

.card5-animate {
  animation-delay: 0.8s;
}

.card6-animate {
  animation-delay: 1s;
}
</style>

<style>
.dv-scroll-board .header {
    background-image: url('./../assets/image/table1.png') !important;
    background-size: 100% 100%;
    background-color: transparent !important;
}

.dv-scroll-board .header .header-item {
    opacity: 0.8;
}

.dv-scroll-board .rows .ceil {
    opacity: 0.8;
}

.dv-scroll-board .row-item:nth-child(2n) {
    background-image: url('./../assets/image/table2.png') !important;
    background-size: 100% 100%;
    background-color: transparent !important;
}

.dv-scroll-board .header .left {
    opacity: 1;
}

.tableScrollBoard .time {
    position: absolute;
    left: 893px;
    top: 111px;
    font-size: 18px;
    color: #fff;
}

.tableScrollBoard .close {
    position: absolute;
    left: 1150px;
    top: 87px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}
</style>
