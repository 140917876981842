import * as THREE from "three";
import EventBus from "../bus";
import { secondaryMaterial, trunkMaterial } from "./material";

export function loaderModel(app) {
  return new Promise((resolve) => {
    // 模型控制组
    app.controlGroup = new THREE.Group();
    // 用于存储模型的所有材质
    app.modelMaterials = {};
    // 储存需要交互的模型
    app.rayModel = [];
    app.lineTextures = [];
    app.scene.add(app.controlGroup);
    // const modelUrl = 'model/oildepot1.glb'
    const modelUrl = 'model/model.glb'
    const urls = [
      {
        type: "rgbe",
        url: "texture/royal_esplanade_1k.hdr",
        onLoad: (texture) => {
          const pmremGenerator = new THREE.PMREMGenerator(app.renderer);
          pmremGenerator.compileEquirectangularShader();
          app.envMap = pmremGenerator.fromEquirectangular(texture).texture;
        },
      },
      {
        type: "glb",
        url: modelUrl,
        onLoad: (object) => {
          console.log(object, 123123123);

          app.model = object.scene;
          if(modelUrl.includes('model.glb')) {
            app.isPrimitiveModel = true
          }
          // const floorLight = new THREE.PointLight('#ffffff', 1, 10);
          // floorLight.castShadow = false;
          // const roadLight = new THREE.PointLight('#ffffff', 1, 30);
          // roadLight.castShadow = false;
          const receiveModels = [
            "平面017_1",
            "平面017_2",
            "平面017_3",
            "平面017_4",
          ];
          const bloomModels = [
            "楼间隔",
            "承重柱",
            "玻璃",
            "路灯面",
            "停车线",
            "顶亮面",
            "车流线",
          ];
          app.model.traverse((obj) => {
            // 将所有模型的材质储存在app.modelMaterials，在后续改变材质使用
            if (obj.material) {
              app.modelMaterials[obj.name] = {
                material: obj.material,
              };
              if (obj.name === "其余建筑_1") {
                obj.material.envMap = app.envMap;
              }
            }
            // 将模型的坐标也储存一份，在做楼层动画使用
            let { x, y, z } = obj.position;
            obj.position_tmp = { x, y, z };

            // 接收投影设置
            if (receiveModels.includes(obj.name)) {
              obj.receiveShadow = true;
            } else {
              obj.castShadow = true;
              obj.receiveShadow = false;
            }

            if (obj.name.indexOf("车流线1") > -1) {
              obj.material = secondaryMaterial;
            }

            if (obj.name.indexOf("车流线2") > -1) {
              obj.material = trunkMaterial;
            }

            if (obj.name.indexOf("车流线3") > -1) {
              obj.material = trunkMaterial;
            }

            // if (obj.name.indexOf('路灯面') > -1) {
            //   obj.add(floorLight.clone());
            // }

            // if (obj.name.indexOf('路灯面2') > -1) {
            //   obj.add(roadLight.clone());
            // }

            for (let i = 0; i < bloomModels.length; i++) {
              const value = bloomModels[i];
              if (obj.name.indexOf(value) > -1) {
                obj.layers.enable(1);
                break;
              } else {
                obj.layers.enable(0);
              }
            }
          });
        },
      },
    ];

    let urlsLength = urls.length;
    app.iterateLoad(
      urls,
      (xhr) => {
        let proportion = parseInt((xhr.loaded / xhr.total) * 100);
        if (proportion === 100) {
          EventBus.$emit("changeLoaidng", parseInt(100 / urlsLength));
          urlsLength--;
          if (urlsLength <= 1) {
            EventBus.$emit("changeScene", true);
          }
        }
      },
      () => {
        app.scene.add(app.model);
        resolve();
      }
    );
  });
}

/**
 * 设置模型默认材质
 * @param {*} app
 */
export function setModelDefaultMatrial(app) {
  app.model.traverse((obj) => {
    if (obj.material) {
      obj.material = app.modelMaterials[obj.name].material;
    }
  });
}

/**
 * 清空控制器组
 * @param {*} app
 * @returns
 */
export function destroyControlGroup(app, className) {
  if (app?.controlGroup?.children?.length === 0) {
    return;
  }
  if (className) {
    destroyControlGroupText(app, className);
  }
  for (let i = app.controlGroup.children.length - 1; i > -1; i--) {
    const obj = app.controlGroup.children[i];
    if (obj.isMesh) {
      obj.geometry.dispose();
      obj.material.dispose();
      app.controlGroup.remove(obj);
    }
  }
}

/**
 * 清空控制器组的文本
 * @param {*} app
 * @returns
 */
export function destroyControlGroupText(app, className) {
  const textDoms = document.getElementsByClassName(className);
  for (let i = 0; i < textDoms.length; i++) {
    const textDom = textDoms[i];
    textDom.onclick = null;
  }
  app.instance.removeAll(app.controlGroup);
}
