import { render, staticRenderFns } from "./VehicleAccessLog.vue?vue&type=template&id=b5d2e3b2&scoped=true"
import script from "./VehicleAccessLog.vue?vue&type=script&lang=js"
export * from "./VehicleAccessLog.vue?vue&type=script&lang=js"
import style0 from "./VehicleAccessLog.vue?vue&type=style&index=0&id=b5d2e3b2&prod&lang=less"
import style1 from "./VehicleAccessLog.vue?vue&type=style&index=1&id=b5d2e3b2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5d2e3b2",
  null
  
)

export default component.exports