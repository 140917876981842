<template>
    <div>
        <!-- 显示报警数量、预警数量、故障数量等数据 -->
        <div class="event-stats">
            <span>全部（{{ totalEvents }}）</span>
            <span>报警（{{ alarmCount }}）</span>
            <span>预警（{{ warningCount }}）</span>
            <span>故障（{{ faultCount }}）</span>
        </div>
        <!-- 事件表格 -->
        <table class="event-table">
            <tbody>
                <tr v-for="(event, index) in events" :key="index">
                    <td :style="levelStyle(event.level)">{{ event.level }}</td>
                    <td>{{ event.location }}</td>
                    <td>{{ event.type }}</td>
                    <td>{{ event.time }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            events: [
                { level: '紧急', location: '03楼-A-103', type: '火灾报警', time: '2021-02-09 14:28' },
                { level: '严重', location: '05楼-B-201', type: '电梯报警', time: '2021-02-09 16:10' },
                { level: '严重', location: '06楼-A-301', type: '安防报警', time: '2021-02-10 18:10' },
                { level: '一般', location: '07楼-B-204', type: '门禁报警', time: '2021-02-10 20:10' },
                { level: '一般', location: '08楼-A-402', type: '充电故障', time: '2021-02-10 22:10' },
                // 添加更多事件
            ]
        };
    },
    computed: {
        // 计算总数
        totalEvents() {
            return this.events.length;
        },
        // 计算报警数量
        alarmCount() {
            return this.events.filter(event => event.type.includes('报警')).length;
        },
        // 计算预警数量
        warningCount() {
            return this.events.filter(event => event.type.includes('预警')).length;
        },
        // 计算故障数量
        faultCount() {
            return this.events.filter(event => event.type.includes('故障')).length;
        }
    },
    methods: {
        // 根据 level 返回相应的背景颜色和文本颜色
        levelStyle(level) {
            const colorMap = {
                '紧急': { backgroundColor: '#D52E15', color: '#fff' },
                '严重': { backgroundColor: '#F58800', color: '#fff' },
                '一般': { backgroundColor: '#FFEF00', color: '#000' }
            };
            return colorMap[level] || { backgroundColor: '#fff', color: '#000' }; // 默认白色背景和黑色文字
        }
    }
};
</script>

<style scoped>
.event-stats {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-bottom: 20px; /* 下边距 */
}

.event-stats span {
    margin: 0 10px; /* 左右间距 */
    font-size: 16px;
    color: #a1e4ff;
}

.event-table {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-collapse: collapse;
}

.event-table td {
    padding: 10px;
    text-align: left;
    vertical-align: top;
    /*border: 1px solid #333;*/
}

.event-table tr:hover {
    background-color: #222;
}
</style>