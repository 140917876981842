<template>
    <div>
        <table class="ticket-table">
            <thead>
            <tr>
                <th>等级</th>
                <th>房间号</th>
                <th>类型</th>
                <th>时间</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(ticket, index) in tickets" :key="index">
                <td>{{ ticket.level }}</td>
                <td>{{ ticket.room }}</td>
                <td>{{ ticket.type }}</td>
                <td>{{ ticket.time }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tickets: [
                { level: '严重', room: '10楼-C-103', type: '安防报警', time: '2021-02-09 18:42' },
                { level: '严重', room: '06楼-A-101', type: '门禁报警', time: '2021-02-09 15:20' },
                { level: '一般', room: '02楼-A-102', type: '充电故障', time: '2021-02-08 09:16' },
                // 添加更多工单
            ]
        };
    }
};
</script>

<style scoped>
.ticket-table {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-collapse: collapse;
}

.ticket-table th,
.ticket-table td {
    padding: 10px;
    text-align: left;
    vertical-align: top;
    border: 1px solid #333;
}

.ticket-table th {
    background-color: #333;
    color: #fff;
}

.ticket-table tr:nth-child(even) {
    background-color: #2b2c35;
}

.ticket-table tr:hover {
    background-color: #222;
}
</style>