<template>
    <div class="VehicleInfo">
        <div class="title">
            <span>{{ title }}</span>
        </div>
        <div class="content">
            <slot>
                <div class="VehicleInfoBoard">
                    <div class="VehicleInfoBoard-item">
                        <div class="VehicleInfo_icon VehicleInfo_icon--building"></div>
                        <p class="VehicleInfo_subtext">停车位</p>
                        <p class="VehicleInfo_text">2,600</p>
                    </div>
                    <div class="VehicleInfoBoard-item">
                        <div class="VehicleInfo_icon VehicleInfo_icon--home"></div>
                        <p class="VehicleInfo_subtext">注册车辆</p>
                        <p class="VehicleInfo_text">1,800</p>
                    </div>

                    <div class="VehicleInfoBoard-item">
                        <div class="VehicleInfo_icon VehicleInfo_icon--person"></div>
                        <p class="VehicleInfo_subtext">日均利用率</p>
                        <p class="VehicleInfo_text">95%</p>
                    </div>

                    <div class="VehicleInfoBoard-item">
                        <div class="VehicleInfo_icon VehicleInfo_icon--parking"></div>
                        <p class="VehicleInfo_subtext">当日车流量</p>
                        <p class="VehicleInfo_text">3,260</p>
                    </div>

                    <div class="VehicleInfoBoard-item">
                        <div class="VehicleInfo_icon VehicleInfo_icon--car"></div>
                        <p class="VehicleInfo_subtext">当日订单额</p>
                        <p class="VehicleInfo_text">36,280</p>
                    </div>

                    <div class="VehicleInfoBoard-item">
                        <div class="VehicleInfo_icon VehicleInfo_icon--iot"></div>
                        <p class="VehicleInfo_subtext">当日报警数</p>
                        <p class="VehicleInfo_text">8</p>
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VehicleInfo',
    props: {
        title: {
            type: String,
            default: '基础信息'
        }
    }
};
</script>

<style lang="less">
.VehicleInfo {
  display: block;
  overflow: hidden;
  width: 580px;
  height: 33%;
  margin: 0 10px 20px 10px;
  background-image: url('./../../assets/image/card_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .title {
    height: 30px;
    padding-left: 34px;
    padding-top: 20px;

    > span {
      font-size: 24px;
      font-weight: normal;
      font-style: italic;
      color: #a1e4ff;
      padding-bottom: 20px;

      background: linear-gradient(-35deg, #a0cbd9 0%, #ddfdff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .content {
    display: block;
    height: 80%;
    position: relative;
  }
}
</style>

<style>
body {
    background-color: #1e1f26;
    color: white;
    font-family: Arial, sans-serif;
}

.VehicleInfoBoard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 20px;
}

.VehicleInfoBoard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
}

.VehicleInfoBoard-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.VehicleInfo_icon {
    width: 48px; /* 设置图标宽度 */
    height: 48px; /* 设置图标高度 */
    margin-bottom: 8px;
    background-size: contain;
    background-repeat: no-repeat;
}

.VehicleInfo_text {
    font-size: 1.5rem;
    font-weight: bold;
}

.VehicleInfo_subtext {
    color: #999;
}

.VehicleInfo_icon--building { background-image: url('./../../assets/image/building.png'); }
.VehicleInfo_icon--home { background-image: url('./../../assets/image/home.png'); }
.VehicleInfo_icon--person { background-image: url('./../../assets/image/person.png'); }
.VehicleInfo_icon--parking { background-image: url('./../../assets/image/parking.png'); }
.VehicleInfo_icon--car { background-image: url('./../../assets/image/car.png'); }
.VehicleInfo_icon--iot { background-image: url('./../../assets/image/iot.png'); }
</style>