<template>
    <div class="TabSwitcher">
        <div class="TabSwitcher-title">
            <span>
                {{ title }}
            </span>
            <div class="tabs">
                <button @click="activeTab = 'events'">事件</button>
                <button @click="activeTab = 'tickets'">工单</button>
            </div>
        </div>
        <div class="content">
            <slot>
                <div>
                    <div v-if="activeTab === 'events'">
                        <EventTable />
                    </div>
                    <div v-else-if="activeTab === 'tickets'">
                        <TicketTable />
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import EventTable from './EventTable.vue';
import TicketTable from './TicketTable.vue';

export default {
    props: {
        title: {
            type: String,
            default: '报警数据'
        }
    },
    components: {
        EventTable,
        TicketTable
    },
    data() {
        return {
            activeTab: 'events' // 默认显示“事件”选项卡
        };
    }
};
</script>

<style lang="less">
.TabSwitcher {
    display: block;
    overflow: hidden;
    width: 580px;
    height: 66%;
    margin: 0 10px 20px 10px;
    background-image: url('./../../assets/image/card_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .TabSwitcher-title {
        display: flex; /* 使用 Flexbox 布局 */
        align-items: center; /* 垂直居中对齐 */
        padding-left: 34px;
        padding-top: 20px;

        > span {
            font-size: 24px;
            font-weight: normal;
            font-style: italic;
            color: #a1e4ff;
            padding-bottom: 20px;

            background: linear-gradient(-35deg, #a0cbd9 0%, #ddfdff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .tabs {
        display: flex;
        justify-content: center; /* 使按钮居中 */
        gap: 10px; /* 按钮之间的间距 */
        margin-left: auto; /* 将 .tabs 推到右侧 */
        margin-right: auto; /* 将 .tabs 推到右侧 */
        margin-bottom: 20px;
    }

    .tabs button {
        padding: 10px 20px;
        background-color: #333;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 4px; /* 添加圆角 */
    }

    .tabs button:hover {
        background-color: #555;
    }

    .tabs button:active {
        background-color: #777;
    }

    .content {
        display: block;
        height: 80%;
        position: relative;
    }
}
</style>
